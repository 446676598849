import { Marker, Popup } from 'react-leaflet';
import React, { useRef, useMemo } from 'react';

/**
 * Draggable marker component. Used on the map for selecting the AOI.
 */
function DraggableMarker({ position, onDragEnd, onRemove }) {
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          const newPosition = marker.getLatLng();
          onDragEnd(newPosition);
        }
      },
    }),
    [onDragEnd],
  );

  return (
    <Marker draggable eventHandlers={eventHandlers} position={position} ref={markerRef}>
      <Popup minWidth={90}>
        <button onClick={onRemove}>Remove marker</button>
      </Popup>
    </Marker>
  );
}

export default DraggableMarker;
