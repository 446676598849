import { useState } from 'react';

const useError = () => {
  const [error, setError] = useState({ short: '', details: '' });

  const handleError = (short, details) => {
    setError({ short, details });
  };

  return { error, handleError };
};

export default useError;
