import React from 'react';
import ErrorScreen from '../screens/ErrorScreen';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(err, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    console.log('ERROR: ', err);
    console.log('INFO: ', info);
    this.setState({ error: err, errorInfo: info });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorScreen error={this.state.error} errorMessage={this.state.errorInfo} />;
    }

    return this.props.children;
  }
}
