import React from 'react';
import { Box, Typography } from '@mui/material';
import Sidebar from '../components/Sidebar';
import '../styles/Error.css';
import ReactMarkdown from 'react-markdown';

const drawerWidth = 240;

export default function ErrorScreen({ error = 'Unknown error', errorMessage }) {
  const useMarkdown = !!((errorMessage && errorMessage.componentStack));

  let fullErrMessage = 'No error message available';

  if (useMarkdown === true) {
    fullErrMessage = `\`\`\`\n${errorMessage.componentStack}\n\`\`\``;
  } else if (errorMessage) {
    fullErrMessage = errorMessage;
  }

  console.log('err: ', error);
  console.log('msg: ', errorMessage);
  return (
    <div id="error-background">
      <Box sx={{ display: 'flex' }}>
        <Sidebar />

        <Box
          component="main"
          sx={{
            flexGrow: 1, p: 3, m: 1, width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Box
            sx={{
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh',
            }}
          >
            <img className="error-image" src="error-image.png" alt="error" width="30%" height="30%" style={{ marginBottom: '10px' }} />
            <Typography variant="h5" marginBottom="10px">
              {`Error: ${error}`}
            </Typography>

            {useMarkdown ? (
              <ReactMarkdown>
                {fullErrMessage}
              </ReactMarkdown>
            ) : (
              <Typography>
                {fullErrMessage}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
}
