import moment from 'moment';
import { tz } from 'moment-timezone';

export function getDuration(timestamps) {
  // Check if timestamps are not [] or ['', '']
  if (!timestamps || !timestamps.length || (timestamps.length === 2 && !timestamps[0] && !timestamps[1])) {
    return '';
  }

  // If we have only 1 timestamp, show how long it's been running for.
  if ((timestamps[0] && !timestamps[1]) || (timestamps[0] !== '' && timestamps[1] === '')) {
    const current = moment().tz(moment.tz.guess());
    const timeSpent = moment.duration(current.diff(timestamps[0]));

    const days = timeSpent.days();
    const hours = timeSpent.hours();
    const minutes = timeSpent.minutes();
    const seconds = timeSpent.seconds();

    if (days > 0) return `${days} days, ${hours} h, ${minutes} m, ${seconds} s so far.`;
    if (hours > 0) return `${hours} h, ${minutes} m, ${seconds} s so far.`;
    if (minutes > 0) return `${minutes} m, ${seconds} s so far.`;
    if (seconds > 0) return `${seconds} s so far.`;
    return 'Process is still running.';
  }

  // Extract start and end timestamp
  const start = moment(timestamps[0], 'YYYY-MM-DD HH:mm:ss');
  const end = moment(timestamps[timestamps.length - 1], 'YYYY-MM-DD HH:mm:ss');

  // Calculate difference
  const diff = moment.duration(end.diff(start));

  // Calculate number of days/hours/minutes/seconds
  const days = diff.days();
  const hours = diff.hours();
  const minutes = diff.minutes();
  const seconds = diff.seconds();

  let duration = '';
  if (days) {
    duration = `${days} d, ${hours} h, ${minutes} m, ${seconds} s.`;
  } else {
    duration = `${hours} h, ${minutes} m, ${seconds} s.`;
  }

  return duration;
}

/**
 * Converts a timestamp to the user's timezone.
 * @param {String} timestamp - A timestamp in UTC, formatted as YYYY-MM-DD HH:mm:ss.
 * @returns {String} A timestamp in the user's timezone, formatted as YYYY-MM-DD HH:mm:ss.
 */
export function convertToUserTimezone(timestamp) {
  const userTimeZone = moment.tz.guess();
  const localDateTime = moment.utc(timestamp).tz(userTimeZone).format('YYYY-MM-DD HH:mm:ss');

  return localDateTime;
}
