import { useEffect, useState } from 'react';
import { getAllProjects } from '../util/launchpadRequests';

/**
 * Retrieves all available projects from the file system.
 * @returns {Array} An array of all projects.
 */
export default function useAllProjects() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const projectsData = await getAllProjects();
      setProjects(projectsData);
    };

    fetchData();
  }, []);

  return { projects };
}
