// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#error-background {
    background-image: radial-gradient( circle at 80%,  rgba(28,71,117,1) 0.8%, rgba(2,2,2,1) 39.6%, rgba(2,2,2,1) 59.7%, rgba(28,71,117,1) 99.3% );
    color: white;
    border: white;
    background-size: 100%;
    min-height: 100vh;
    height: auto;
}

.error-image {
    max-width: 600px;
    height: auto;
    margin-bottom: 10px;
}
  
@media screen and (max-width: 600px) {
    .error-image {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Error.css"],"names":[],"mappings":"AAAA;IACI,8IAA8I;IAC9I,YAAY;IACZ,aAAa;IACb,qBAAqB;IACrB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":["#error-background {\n    background-image: radial-gradient( circle at 80%,  rgba(28,71,117,1) 0.8%, rgba(2,2,2,1) 39.6%, rgba(2,2,2,1) 59.7%, rgba(28,71,117,1) 99.3% );\n    color: white;\n    border: white;\n    background-size: 100%;\n    min-height: 100vh;\n    height: auto;\n}\n\n.error-image {\n    max-width: 600px;\n    height: auto;\n    margin-bottom: 10px;\n}\n  \n@media screen and (max-width: 600px) {\n    .error-image {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
