import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider,
} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import '../styles/Sidebar.css';
// Icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InboxIcon from '@mui/icons-material/Inbox';
import MailIcon from '@mui/icons-material/Mail';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import BugReportIcon from '@mui/icons-material/BugReport';
import logout from '../util/logout';

/**
 * Sidebar component.
 */
export default function Sidebar() {
  return (
    <div id="toolbar">

      <div className="logo-container">
        <img src="logo-white.png" alt="logo" width="150" height="55" />
      </div>

      <List>
        <Divider />

        <ListItem key="Project Setup" disablePadding>
          <ListItemButton component={Link} to="/setup">
            <ListItemIcon>
              <InboxIcon className="sidebar-icons" />
            </ListItemIcon>
            <ListItemText primary="Project Setup" />
          </ListItemButton>
        </ListItem>

        <Divider />

        <ListItem key="Project Settings" disablePadding>
          <ListItemButton component={Link} to="/settings">
            <ListItemIcon>
              <SettingsIcon className="sidebar-icons" />
            </ListItemIcon>
            <ListItemText primary="Project Settings" />
          </ListItemButton>
        </ListItem>

        <Divider />

        <ListItem key="New Launchpad" disablePadding>
          <ListItemButton component={Link} to="/newlaunchpad">
            <ListItemIcon>
              <MailIcon className="sidebar-icons" />
            </ListItemIcon>
            <ListItemText primary="Launchpad" />
          </ListItemButton>
        </ListItem>

        <Divider />

        <ListItem key="Profile" disablePadding>
          <ListItemButton component={Link} to="/profile">
            <ListItemIcon>
              <AccountCircleIcon className="sidebar-icons" />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItemButton>
        </ListItem>

        <Divider />

        <ListItem key="Logout" disablePadding>
          <ListItemButton onClick={logout}>
            <ListItemIcon>
              <LogoutIcon className="sidebar-icons" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>

        <Divider />
      </List>
      <Divider />
    </div>
  );
}
