import * as React from 'react';
import {
  Box,
  Button,
  Typography,
  Divider,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { buttonStyle } from '../util/settingsUtils';
import { getDuration, convertToUserTimezone } from '../util/dateTimeUtils';
import { isPolygonValid } from '../util/geometry';

/**
 * @param {string} project - the project name
 * @param {string} stack - the process stack
 * @param {string} aoi - the process AOI. This is null for stack preparation.
 * @param {Object} process - the process shown in the box
 * @param {string} process.type - the type of the process (preparation, network, densification)
 * @param {string} process.status - the process status
 * @param {string[]} process.logs - the process logs
 * @param {string[]} process.timestamps - the process start and end timestamps
 * @param {Object} states - the states for the buttons, loading bar, etc.
 * @param {boolean} states.progressBar - the state of the progress bar
 * @param {boolean} states.buttonDisabled - process button enabled/disabled
 * @param {boolean} states.buttonText - the text on the process button
 * @param {function} onRunProcess - the function to run a process
 * @param {function} onKillProcess - the function to kill a process
 * @returns {React.Component} - the process box
 */
export default function ProcessBox({
  project, stack, aoi, process, states, onRunProcess, onKillProcess,
}) {
  const joinedLogs = (process.logs !== null && process.logs !== undefined) ? Object.values(process.logs).join('\n') : '';

  let start_time = '';
  let end_time = '';

  if (process.timestamps !== null) {
    if (process.timestamps[0] !== null && process.timestamps[0] !== '' && process.timestamps[0] !== undefined) {
      start_time = convertToUserTimezone(process.timestamps[0]);
    }

    if (process.timestamps[1] !== null && process.timestamps[1] !== '' && process.timestamps[1] !== undefined) {
      end_time = convertToUserTimezone(process.timestamps[1]);
    }
  }

  return (
    <Box sx={{
      width: '33%', marginRight: 2, bgcolor: '#222222', border: '1px solid', borderColor: '#ffffff', borderRadius: 3, overflow: 'hidden',
    }}
    >

      {/* Progress bar */}
      {states.progressBar && (
        <LinearProgress sx={{ height: 7, '& .MuiLinearProgress-bar': { backgroundColor: 'rgb(16, 52, 88)' } }} />
      )}

      {/* Process type and button */}
      <Box sx={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 1, alignItems: 'center',
      }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5" style={{ whiteSpace: 'pre-line' }}>
            {process.type}
          </Typography>

          {(process.type === 'Network' || process.type === 'Densification') && (
            <Tooltip title={isPolygonValid(aoi) ? 'AOI looks fine!' : 'AOI is invalid!'}>
              {isPolygonValid(aoi) ? (
                <CheckCircleIcon sx={{ marginLeft: 1, color: 'green' }} />
              ) : (
                <ErrorIcon sx={{ marginLeft: 1, color: 'red' }} />
              )}
            </Tooltip>
          )}
        </Box>

        <Button
          variant="contained"
          disabled={states.buttonDisabled}
          sx={buttonStyle}
          style={{ width: '25%' }}
          onClick={() => {
            states.buttonText === 'Run'
              ? onRunProcess(process.type)
              : onKillProcess(process.type);
          }}
        >
          {states.buttonText}
        </Button>
      </Box>

      {/* Progress logs */}
      <Divider variant="middle" sx={{ borderColor: 'lightgrey' }} />
      <Box sx={{ margin: 2 }}>

        <Typography style={{ whiteSpace: 'pre-line' }}>
          {joinedLogs}
        </Typography>
      </Box>

      {/* Timestamps */}

      <Divider variant="middle" style={{ borderColor: 'lightgrey' }} />
      <Box sx={{ margin: 2, marginTop: 1 }}>

        <Typography>
          {' '}
          Start time:
          {' '}
          {start_time}
        </Typography>
        <Typography>
          {' '}
          End time:
          {' '}
          {end_time}
        </Typography>
        <Typography>
          {' '}
          Total duration:
          {' '}
          {getDuration([start_time, end_time])}
        </Typography>
      </Box>
    </Box>
  );
}
