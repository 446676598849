import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { auth as authUrl } from '../util/API';
import '../styles/Welcome.css';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  /**
   * Logs the user in by sending a POST request to the backend.
   * If the login is successful, the user is redirected to the setup page.
   * If the login is unsuccessful, an error message is displayed.
   */
  const login = () => {
    const errorDiv = document.getElementsByClassName('errorDiv')[0];
    errorDiv.innerHTML = '';

    axios
      .post(authUrl, JSON.stringify({ username, password }), {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        console.log(response);
        navigate('/setup');

        sessionStorage.setItem('user', JSON.stringify(response.data.user));
        sessionStorage.setItem('token', JSON.stringify(response.data.token));
        sessionStorage.setItem('login_status', JSON.stringify(true));
        sessionStorage.setItem('user_id', JSON.stringify(response.data.user.id));
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          if (error.response.data.username != null) {
            if (error.response.data.username.includes('This field may not be blank.')) {
              const e = document.createElement('div');
              e.innerHTML = 'Username cannot be empty.';
              e.className = 'error';
              errorDiv.appendChild(e);
            }
          }
          if (error.response.data.non_field_errors != null) {
            if (error.response.data.non_field_errors.includes('Unable to log in with provided credentials.')) {
              const e = document.createElement('div');
              e.innerHTML = 'Unable to log in with provided credentials. Make sure the spelling is correct.';
              e.className = 'error';
              errorDiv.appendChild(e);
            }
          }
        } else {
          alert('Something went wrong, please try again');
        }
      });
  };

  /**
   * Updates the username state variable when the user types in the username field.
   * @param {*} event
   */
  const updateUsername = (event) => {
    setUsername(event.target.value);
  };

  /**
   * Updates the password state variable when the user types in the password field.
   * @param {*} event
   */
  const updatePassword = (event) => {
    setPassword(event.target.value);
  };

  /**
   * Logs the user in when the user presses the enter key.
   * @param {*} event
   */
  const handleEnterLogin = (event) => {
    if (event.key === 'Enter') {
      login(event);
    }
  };

  return (
    <div className="all">
      <div className="welcomePages">
        <h2 style={{ fontSize: '30px' }}>Login Here</h2>
        <label style={{ fontSize: '20px', paddingTop: '40px' }}>
          Username:
          <input
            type="text"
            name="username"
            onChange={updateUsername}
          />
        </label>
        <br />
        <label id="login" style={{ fontSize: '20px' }}>
          Password:
          <input
            type="password"
            name="password"
            onChange={updatePassword}
            onKeyDown={handleEnterLogin}
          />
        </label>
        <br />
        <button onClick={login} type="primary" className="btn" id="loginBtn">Login</button>
      </div>
      <div className="errorDiv" style={{ fontSize: '20px' }} />
    </div>
  );
}
