import * as React from 'react';
import axios from 'axios';
import _ from 'lodash';
import {
  Box, Button, Divider, TextField, Typography, Autocomplete, Switch, FormControlLabel,
} from '@mui/material';
import {
  getStacksUrl, createProjectUrl, retrieveProjects, getProjectAoisUrl,
} from '../util/API';
import Sidebar from '../components/Sidebar';

import InactiveTimer from '../components/inactiveTimer';
import logout from '../util/logout';

import MapComponent from '../components/map';

import '../styles/Setup.css';
import { checkPolygonOverlap, extractPoints } from '../util/geometry';
import useProjectStacks from '../hooks/useProjectStacks';
import useAllProjects from '../hooks/useAllProjects';
import getProjectAOIs from '../util/setupRequests';

const drawerWidth = 240;

export default function Setup() {
  const { projects } = useAllProjects();
  const [projectName, setProjectName] = React.useState('');
  const [projectToCopyAoi, setProjectToCopyAoi] = React.useState('');

  const [stacks, setStacks] = React.useState([]);
  const [availableStacks, setAvailableStacks] = React.useState({});
  const [chosenStackAOI, setChosenStackAOI] = React.useState('');

  const [createBtn, setCreateBtn] = React.useState(true);

  const [output, setOutput] = React.useState('');

  const [copyAoiToggle, setCopyAoiToggle] = React.useState(false);
  const { projectStacks: currentStacks } = useProjectStacks(projectToCopyAoi);
  const [projectStackToCopyAoi, setProjectStackToCopyAoi] = React.useState('');

  // Map state variables
  const [networkAoi, setNetworkAoi] = React.useState('POLYGON(())');
  const [networkAoiError, setNetworkAoiError] = React.useState(false);
  const [densificationAoi, setDensificationAoi] = React.useState('POLYGON(())');
  const [densificationAoiError, setDensificationAoiError] = React.useState(false);

  const [intersectingStacks, setIntersectingStacks] = React.useState([]);

  const jobs = '200';
  const token = JSON.parse(sessionStorage.getItem('token'));

  /**
   * Copies the network and densification AOI from the selected project and stack.
   */
  const copyAOI = async (name, stack) => {
    const aois = await getProjectAOIs(name, stack);
    console.log('AOIS:', aois);
    setNetworkAoi(aois.network_aoi);
    setDensificationAoi(aois.densification_aoi);
  };

  /**
   * Creates a new project with the given parameters.
   */
  const createProject = () => {
    if (intersectingStacks.filter(([name, aoi, satellite, selected, inAzure]) => selected).length === 0 || projectName === '') {
      setOutput(`${output}\nOne of the required parameters is empty!\n`);
    } else if (networkAoiError || networkAoi === 'POLYGON(())' || networkAoi === '') {
      setOutput(`${output}\nThe network AOI is not in the correct format!\n`);
    } else if (densificationAoiError || densificationAoi === 'POLYGON(())' || densificationAoi === '') {
      setOutput(`${output}\nThe densification AOI is not in the correct format!\n`);
    } else {
      let finalNetworkAoi;
      let finalDensificationAoi;

      const networkPoints = networkAoi.replace(/\)\)$/, '').replace(/^POLYGON\(\(/, '').split(',').map((point) => point.trim());
      const densificationPoints = densificationAoi.replace(/\)\)$/, '').replace(/^POLYGON\(\(/, '').split(',').map((point) => point.trim());

      if (networkPoints[0] !== networkPoints[networkPoints.length - 1]) {
        finalNetworkAoi = `POLYGON((${networkAoi.replace(/\)\)$/, '').replace(/^POLYGON\(\(/, '')},${networkPoints[0]}))`;
      } else {
        finalNetworkAoi = networkAoi;
      }

      if (densificationPoints[0] !== densificationPoints[densificationPoints.length - 1]) {
        finalDensificationAoi = `POLYGON((${densificationAoi.replace(/\)\)$/, '').replace(/^POLYGON\(\(/, '')},${densificationPoints[0]}))`;
      } else {
        finalDensificationAoi = densificationAoi;
      }

      console.log('Project name:', projectName);
      console.log('Network AOI:', finalNetworkAoi);
      console.log('Densification AOI:', finalDensificationAoi);

      const chosenStacks = intersectingStacks.filter(([name, aoi, satellite, selected, inAzure]) => selected).map(([name, aoi, satellite, selected, inAzure]) => name);

      console.log('Chosen stacks: ', chosenStacks);

      axios
        .post(
          createProjectUrl,
          {
            project: projectName,
            stacks: chosenStacks,
            network_wkt: finalNetworkAoi,
            densification_wkt: finalDensificationAoi,
            densification_nr_jobs: jobs,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${token}`,
            },
          },
        )
        .then((response) => {
          console.log(response);
          setOutput(`${output}\nProject ${projectName} with the stacks ${chosenStacks.join(', ')} was created successfully! \n`);
        })
        .catch((error) => {
          console.log(error);
          setOutput(`${output}\nUNKOWN ERROR: Project creation failed! \n`);
        });
    }
  };

  /**
   * Retrieves all available stacks from the backend.
   */
  React.useEffect(() => {
    axios
      .get(
        getStacksUrl,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          },
        },
      )
      .then((response) => {
        if (response.data !== '{}') {
          const stacksWithAOI = {};

          for (const key in response.data) {
            if (response.data.hasOwnProperty(key)) {
              stacksWithAOI[key] = {};
              stacksWithAOI[key].aoi = response.data[key].footprint;
              stacksWithAOI[key].satellite = response.data[key].satellite;
              stacksWithAOI[key].inAzure = response.data[key].inAzure;
            }
          }

          console.log('Stacks with AOI: ', stacksWithAOI);

          setAvailableStacks(stacksWithAOI);
          setStacks(Object.keys(response.data));
        } else {
          console.log('response was empty');
          setAvailableStacks({});
          setStacks([]);
        }
      })
      .catch((error) => {
        console.log(`There was an error while retrieving the stacks data: ${error}`);
        setAvailableStacks({});
        setStacks([]);
      });
  }, []);

  /**
   * Computes the intersecting stacks when the network AOI changes.
   */
  React.useEffect(() => {
    if (extractPoints(networkAoi).length >= 3) {
      const matchingStacks = [];

      for (const key in availableStacks) {
        try {
          const { aoi } = availableStacks[key];

          const stackAoiPoints = Object.values(extractPoints(aoi))
            .map(({ lng, lat }) => [lng, lat]);
          const networkAoiPoints = Object.values(extractPoints(networkAoi))
            .map(({ lng, lat }) => [lng, lat]);

          if (checkPolygonOverlap(stackAoiPoints, networkAoiPoints)) {
            matchingStacks.push([key, aoi, availableStacks[key].satellite, false, availableStacks[key].inAzure]);
          }
        } catch (error) {
          console.log('Error: ', error);
        }
      }

      setIntersectingStacks(matchingStacks);
      console.log('Intersecting stacks: ', matchingStacks.map((stack) => stack[0]));
    } else {
      console.log('Network AOI is not a polygon');
      setIntersectingStacks([]);
    }
  }, [networkAoi, availableStacks]);

  /**
   * Checks for inactivity every 60 minutes.
   */
  React.useEffect(() => {
    const timer = new InactiveTimer({
      timeout: 3600,
      onTimeout: () => {
        logout(); // If timer expires when the user is in the app
      },
    });

    return () => {
      timer.cleanup();
    };
  }, []);

  /**
   * Checks if the network and densification AOIs entered are not empty,
   * contain more than 2 points and are in the correct format.
   * @returns True if the AOIs are in the correct format, false otherwise.
   */
  const areAOIsCorrect = () => {
    // If the AOIs are empty or there is an error -> not correct
    if (networkAoiError || densificationAoiError) return false;
    if (networkAoi === 'POLYGON(())' || networkAoi === '') return false;
    if (densificationAoi === 'POLYGON(())' || densificationAoi === '') return false;

    const networkPoints = extractPoints(networkAoi);
    const densificationPoints = extractPoints(densificationAoi);

    if (networkPoints.length < 3) return false;
    if (densificationPoints.length < 3) return false;

    return true;
  };

  return (
    <div id="darkbackground">

      <Box sx={{ display: 'flex' }}>

        {/* Left sidebar */}
        <Sidebar />

        {/* Main content */}
        <Box
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            p: 3,
            m: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >

          <h1>Project</h1>

          { /* Text fields for project name, copying aoi. */}
          <Box sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '10px', alignItems: 'center', marginTop: '10px', marginBottom: '20px',
          }}
          >
            <Autocomplete
              disablePortal
              freeSolo
              id="combo-box-demo"
              options={projects}
              autoHighlight
              sx={{ width: 400 }}
              inputValue={projectName}
              onInputChange={(event, value) => {
                setProjectName(value === null ? '' : value.toLowerCase());
                setCreateBtn(value !== null && intersectingStacks.filter(([name, aoi, satellite, selected, inAzure]) => selected).length > 0);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Project name"
                  InputLabelProps={{
                    shrink: (params.inputProps.value || projectName !== ''), // Apply the shrink effect when there is a selected value or user input
                    style: {
                      color: 'white', // Set the label text color to white
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'white !important', // Set the outline color to white
                      },
                      '& input': {
                        color: 'white !important', // Set the input text color to white
                      },
                      '& .MuiAutocomplete-popupIndicator': {
                        color: 'white !important', // Set the color of the arrow icon to white
                      },
                      '& .MuiAutocomplete-clearIndicator': {
                        color: 'white !important', // Set the color of the "x" icon to white
                      },
                    },
                  }}
                />
              )}
            />

            <FormControlLabel
              control={(
                <Switch
                  checked={copyAoiToggle}
                  onChange={() => {
                    copyAoiToggle ? setCopyAoiToggle(false) : setCopyAoiToggle(true);
                  }}
                  color="primary"
                  sx={{ marginLeft: '10px', marginRight: '10px' }}
                />
              )}
              label="Copy AOIs"
              labelPlacement="left"
            />

            {copyAoiToggle
              && (
                <>
                  <Autocomplete
                    disablePortal
                    freeSolo
                    id="combo-box-demo"
                    options={projects}
                    autoHighlight
                    sx={{ width: 400, marginRight: 5 }}
                    inputValue={projectToCopyAoi}
                    onInputChange={(event, value) => {
                      setProjectToCopyAoi(value === null ? '' : value.toLowerCase());
                      // setCreateBtn(value !== null && intersectingStacks.filter(([name, aoi, satellite, selected, inAzure]) => selected).length > 0);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Project to copy the AOI from"
                        InputLabelProps={{
                          shrink: (params.inputProps.value || projectName !== ''),
                          style: {
                            color: 'white',
                          },
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'white !important',
                            },
                            '& input': {
                              color: 'white !important',
                            },
                            '& .MuiAutocomplete-popupIndicator': {
                              color: 'white !important',
                            },
                            '& .MuiAutocomplete-clearIndicator': {
                              color: 'white !important',
                            },
                          },
                        }}
                      />
                    )}
                  />

                  { currentStacks && currentStacks.length > 0 && (
                    <Autocomplete
                      disablePortal
                      freeSolo
                      id="combo-box-demo"
                      options={currentStacks}
                      autoHighlight
                      sx={{ width: 400, marginRight: 5 }}
                      onChange={(event, value) => {
                        setProjectStackToCopyAoi(value === null ? '' : value.name);
                      }}
                      value={currentStacks.find((stack) => stack.name === projectStackToCopyAoi) || null}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.name === value}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          style={{
                            color: option.in_hetzner ? 'inherit' : 'red',
                          }}
                        >
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Stack to copy the AOI from"
                          InputLabelProps={{
                            shrink: (params.inputProps.value || projectName !== ''),
                            style: {
                              color: 'white',
                            },
                          }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'white !important',
                              },
                              '& input': {
                                color: 'white !important',
                              },
                              '& .MuiAutocomplete-popupIndicator': {
                                color: 'white !important',
                              },
                              '& .MuiAutocomplete-clearIndicator': {
                                color: 'white !important',
                              },
                            },
                          }}
                        />
                      )}
                    />
                  )}

                  <Button
                    variant="contained"
                    disabled={false}
                    sx={{
                      marginTop: 2,
                      marginBottom: 2,
                      gridRow: 1,
                      width: 350,
                      backgroundColor: 'rgb(16, 52, 88)',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'rgb(28, 71, 117)',
                      },
                      '&:disabled': {
                        backgroundColor: '#7D8387',
                        color: 'rgba(0, 0, 0, 0.5)',
                      },
                    }}
                    onClickCapture={() => {
                      copyAOI(projectToCopyAoi, projectStackToCopyAoi);
                    }}
                  >
                    Copy AOIs
                  </Button>
                </>
              )}
          </Box>

          {/* Map for selecting AOI */}
          <Box>
            <MapComponent
              networkAoi={networkAoi}
              setNetworkAoi={setNetworkAoi}
              densificationAoi={densificationAoi}
              setDensificationAoi={setDensificationAoi}
              networkAoiError={networkAoiError}
              setNetworkAoiError={setNetworkAoiError}
              densificationAoiError={densificationAoiError}
              setDensificationAoiError={setDensificationAoiError}
              intersectingStacks={intersectingStacks}
              setIntersectingStacks={setIntersectingStacks}
              stackAOI={chosenStackAOI}
            />
          </Box>

          {/* Create project button */}
          <Box>
            {/* All need to be true for the button to be enabled */}
            <Button
              variant="contained"
              disabled={(projectName.trim() === '' || !intersectingStacks.filter(([name, aoi, satellite, selected, inAzure]) => selected).length > 0 || !areAOIsCorrect())}
              sx={{
                marginTop: 2,
                marginBottom: 2,
                gridRow: 1,
                width: 350,
                backgroundColor: 'rgb(16, 52, 88)', // Enabled background color
                color: 'white', // Enabled text color
                '&:hover': {
                  backgroundColor: 'rgb(28, 71, 117)', // Enabled background color on hover
                },
                '&:disabled': {
                  backgroundColor: '#7D8387', // Disabled background color
                  color: 'rgba(0, 0, 0, 0.5)', // Disabled text color
                },
              }}
              onClickCapture={() => {
                createProject();
              }}
            >
              Create Project
            </Button>
          </Box>

          <Divider />

          {/* Output  logs */}
          <Box>
            <h1 style={{ marginTop: 15, marginBottom: 15 }}>Output</h1>
            <Box>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {Object.values(output)}
              </Typography>
            </Box>
          </Box>

        </Box>

      </Box>
    </div>

  );
}
