// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcomePages{
  text-align: center;
  padding: 50px;
    background-size: 100%;
}

.all {
    /*background-color: lightblue;*/
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(28,71,117,1) 0.8%, rgba(2,2,2,1) 39.6%, rgba(2,2,2,1) 59.7%, rgba(28,71,117,1) 99.3% );
    background-size: 100%;
    height: 100vh;
    minHeight: 100vh;
    align-items: center;
    color: white;
}

input {
  margin: 15px;
}
select {
  margin: 15px;
}
.btn{
    margin: 25px;
    font-size: 18px;
}

#extraButton{
  display: none;
}
.error {
    background-color: #ca8686;
    color: black;
    border-style: solid none solid none ;
    border-color: #c93535;
    border-width: 1px;
    padding: 5px;
}
.success {
    background-color: #6cbc6c;
    color: #136413;
    border-style: solid none solid none ;
    border-color: #136413;
    border-width: 1px;
    padding-left: 5px;
}

.errorDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
}`, "",{"version":3,"sources":["webpack://./src/styles/Welcome.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;IACX,qBAAqB;AACzB;;AAEA;IACI,+BAA+B;IAC/B,kKAAkK;IAClK,qBAAqB;IACrB,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;EACE,aAAa;AACf;AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,oCAAoC;IACpC,qBAAqB;IACrB,iBAAiB;IACjB,YAAY;AAChB;AACA;IACI,yBAAyB;IACzB,cAAc;IACd,oCAAoC;IACpC,qBAAqB;IACrB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".welcomePages{\n  text-align: center;\n  padding: 50px;\n    background-size: 100%;\n}\n\n.all {\n    /*background-color: lightblue;*/\n    background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(28,71,117,1) 0.8%, rgba(2,2,2,1) 39.6%, rgba(2,2,2,1) 59.7%, rgba(28,71,117,1) 99.3% );\n    background-size: 100%;\n    height: 100vh;\n    minHeight: 100vh;\n    align-items: center;\n    color: white;\n}\n\ninput {\n  margin: 15px;\n}\nselect {\n  margin: 15px;\n}\n.btn{\n    margin: 25px;\n    font-size: 18px;\n}\n\n#extraButton{\n  display: none;\n}\n.error {\n    background-color: #ca8686;\n    color: black;\n    border-style: solid none solid none ;\n    border-color: #c93535;\n    border-width: 1px;\n    padding: 5px;\n}\n.success {\n    background-color: #6cbc6c;\n    color: #136413;\n    border-style: solid none solid none ;\n    border-color: #136413;\n    border-width: 1px;\n    padding-left: 5px;\n}\n\n.errorDiv {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    left: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
