import React, { useState, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Polygon } from 'react-leaflet';
import { v4 as uuidv4 } from 'uuid';
import {
  Box, TextField, Button, Tooltip, Snackbar, Alert,
} from '@mui/material';
import {
  extractPoints, aoiCenter, calculateMaxBounds, checkPolygonOverlap, closePolygon,
} from '../util/geometry';

import DraggableMarker from './DraggableMarker';
import MarkerHandler from './MarkerHandler';

import { generateTooltips } from '../util/settingsUtils';

const _ = require('lodash');

function SettingsAOIMap({
  myAOI, stackAOI, settings, setter, fields, schema, parentAccordionColor,
}) {
  const [center, setCenter] = useState([30, 0]);
  const [maxBounds, setMaxBounds] = useState([[-100, -200], [100, 200]]);
  const [isPopupClicked, setPopupClicked] = useState(false);

  const [aoiMarkers, setAoiMarkers] = useState([]);
  const [isAoiMarkerRemoved, setIsAoiMarkerRemoved] = useState(false);
  const [aoi, setAoi] = useState(myAOI);

  const [aoiErrors, setAoiErrors] = useState([]);
  const [isAOIValid, setIsAOIValid] = useState(true);
  const [mapReady, setMapReady] = useState(false); // Used for setting the map view on render

  const [showSnackbar, setShowSnackbar] = useState(false); // Used for informing the user when the AOI is confirmed

  const mapRef = useRef();

  console.log(schema);

  /**
     * Checks whether the chosen AOI is correct and can be used.
     * @returns {boolean} - True if the AOI is correct, false otherwise.
     */
  const isAOICorrect = () => {
    const errors = [];

    if (schema) {
      const pattern = new RegExp(schema.pattern);

      if (aoi === null || aoi === undefined || aoi === '' || aoi === 'POLYGON(())') {
        if (!Array.isArray(schema.type) || !schema.type.includes('null')) {
          errors.push({ type: 'NULL_AOI', message: 'AOI is null' });
        }
      } else if (extractPoints(aoi).length >= 3) {
        if (!pattern.test(aoi)) {
          errors.push({ type: 'PATTERN_MISMATCH', message: 'Pattern mismatch' });
        } else {
          const stackPoints = Object.values(extractPoints(stackAOI)).map(({ lng, lat }) => [lng, lat]);
          const chosenAOIPoints = Object.values(extractPoints(aoi)).map(({ lng, lat }) => [lng, lat]);

          if (!checkPolygonOverlap(stackPoints, chosenAOIPoints)) {
            errors.push({ type: 'NO_INTERSECTION', message: 'No intersection with stack AOI' });
          }
        }
      } else {
        errors.push({ type: 'NOT_ENOUGH_POINTS', message: 'Not enough points in AOI' });
      }
    } else {
      errors.push({ type: 'SCHEMA_MISSING', message: 'Schema is missing' });
    }

    setAoiErrors(errors);

    return errors.length === 0;
  };

  /**
     * Checks whether the chosen AOI is correct and can be used.
     */
  const validateAOI = () => {
    const isValid = isAOICorrect();
    setIsAOIValid(isValid);
  };

  /**
     * Checks whether the new AOI is valid.
     */
  useEffect(() => {
    validateAOI();
  }, [aoi]);

  /**
     * Sets the center and zoom/bounds of the map when the map is first rendered.
     * The initial map view is centered and bounded by the chosen stack AOI.
     */
  useEffect(() => {
    // Calculate center and maxBounds based on stackAOI
    const stackPointsFlat = extractPoints(stackAOI).map((point) => [point.lat, point.lng]);

    setCenter(aoiCenter(stackPointsFlat));
    setMaxBounds(calculateMaxBounds(stackPointsFlat));

    // Set initial map view when the component is first rendered
    if (mapRef.current) {
      if (stackPointsFlat.length > 0) {
        mapRef.current.fitBounds(calculateMaxBounds(stackPointsFlat));
      } else {
        mapRef.current.setView([30, 0], 1);
      }
    }
  }, [mapReady]);

  /**
     * Checks whether the two AOIs are equivalent.
     * @param {String} currentAOI
     * @param {String} newAOI
     * @returns true, if the two AOIs are equivalent, false otherwise.
     */
  const areAOIsEquivalent = (currentAOI, newAOI) => {
    if (currentAOI === null || currentAOI === undefined || currentAOI === '' || currentAOI === 'POLYGON(())') {
      return newAOI === null || newAOI === undefined || newAOI === '' || newAOI === 'POLYGON(())';
    }

    const currentAOIPoints = Object.values(extractPoints(currentAOI)).map(({ lng, lat }) => [lng, lat]);
    const newAOIPoints = Object.values(extractPoints(newAOI)).map(({ lng, lat }) => [lng, lat]);

    if (currentAOIPoints.length >= 2) {
      const firstPoint = currentAOIPoints[0];
      const lastPoint = currentAOIPoints[currentAOIPoints.length - 1];

      if (firstPoint[0] !== lastPoint[0] || firstPoint[1] !== lastPoint[1]) {
        currentAOIPoints.push([...firstPoint]);
      }
    }

    if (newAOIPoints.length >= 2) {
      const firstPoint = newAOIPoints[0];
      const lastPoint = newAOIPoints[newAOIPoints.length - 1];

      if (firstPoint[0] !== lastPoint[0] || firstPoint[1] !== lastPoint[1]) {
        newAOIPoints.push([...firstPoint]);
      }
    }

    return _.isEqual(currentAOIPoints, newAOIPoints);
  };

  /**
     * Changes the AOI in the settings file with the chosen AOI.
     */
  const confirmAOI = () => {
    let curr = settings;
    const newAOI = closePolygon(aoi);

    console.log('NEW AOI: ', newAOI);

    for (const field of fields) {
      curr = curr[field];
    }

    if (newAOI === 'POLYGON(())') {
      curr.aoi = null;
    } else {
      curr.aoi = newAOI;
    }

    setter(settings);

    // Show Snackbar after settings are updated
    setShowSnackbar(true);

    // Close Snackbar after 2 seconds (adjust the time as needed)
    setTimeout(() => {
      setShowSnackbar(false);
    }, 2000);
  };

  /**
     * Resets the map to its original form.
     */
  const resetMap = () => {
    const stackPoints = extractPoints(stackAOI);
    const stackPointsFlat = stackPoints.map((point) => [point.lat, point.lng]);

    setCenter(aoiCenter(stackPointsFlat));
    if (mapRef.current) {
      if (stackPointsFlat.length > 0) {
        mapRef.current.fitBounds(calculateMaxBounds(stackPointsFlat));
      } else {
        mapRef.current.setView([30, 0], 1);
      }
    }
  };

  /**
     * Handles the removal of a marker from the map.
     * @param {uuidv4} id - The id of the marker to be removed.
     */
  const removeMarker = (id) => {
    setAoiMarkers(aoiMarkers.filter((marker) => marker.id !== id));
    setIsAoiMarkerRemoved(true);
  };

  /**
     * Clears the markers and AOI.
     */
  const handleClearMap = () => {
    setAoi('');
    setAoiMarkers([]);
    resetMap();
  };

  /**
     * Handles the change of the AOI input.
     */
  useEffect(() => {
    try {
      // Set markers
      const aoiPoints = extractPoints(aoi);
      if (aoi !== 'POLYGON(())' && aoi !== '') {
        const coordinates = aoiPoints.map(({ lng, lat }) => {
          const parsedLat = parseFloat(lat);
          const parsedLng = parseFloat(lng);

          if (!Number.isNaN(parsedLat) && !Number.isNaN(parsedLng)) {
            return {
              id: uuidv4(),
              lat: parsedLat,
              lng: parsedLng,
            };
          }
        }).filter((point) => point !== undefined);

        setAoiMarkers(coordinates);
      }
    } catch (e) {
      console.log('error ', e);
    }
  }, [aoi]);

  return (
    <Box
      sx={{
        backgroundColor: parentAccordionColor, // '#4e6172',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'row',
        padding: '16px',
      }}
    >
      {/* MAP */}
      <MapContainer
        ref={mapRef}
        style={{ height: 350, width: 500, borderRadius: '15px' }}
        center={center}
        whenReady={() => setMapReady(true)}
        maxBounds={maxBounds}
        zoom={1}
        scrollWheelZoom
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <Polygon positions={extractPoints(stackAOI)} />

        {aoiMarkers.map((marker) => (
          <DraggableMarker
            key={marker.id}
            position={[marker.lat, marker.lng]}
            onDragEnd={(newPosition) => {
              const updatedMarkers = aoiMarkers.map((m) => {
                if (m.id === marker.id) {
                  return {
                    ...m,
                    lat: newPosition.lat,
                    lng: newPosition.lng,
                  };
                }
                return m;
              });
              setAoiMarkers(updatedMarkers);
            }}
            onRemove={() => removeMarker(marker.id)}
          />
        ))}

        {aoiMarkers.length >= 3 && <Polygon positions={aoiMarkers} color="red" />}

        <MarkerHandler
          markers={aoiMarkers}
          setMarkers={setAoiMarkers}
          setAoi={setAoi}
          isPopupClicked={isPopupClicked}
          setPopupClicked={setPopupClicked}
          isMarkerRemoved={isAoiMarkerRemoved}
          setIsMarkerRemoved={setIsAoiMarkerRemoved}
        />
      </MapContainer>

      {/* AOI INPUT */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '7%',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '500px' }}>

          <Tooltip
            title={
                            schema ? (
                              <span style={{ whiteSpace: 'pre-line' }}>
                                {generateTooltips(schema)}

                                {aoiErrors.length > 0 && (
                                  <>
                                    {'\n\n'}
                                    <strong style={{ color: 'red' }}>AOI Error:</strong>
                                    {aoiErrors.map((error, index) => (
                                      <div key={index} style={{ color: 'red' }}>{error.message}</div>
                                    ))}
                                  </>
                                )}
                              </span>
                            ) : null
                        }
            placement="top"
            arrow
          >
            <TextField
              error={aoiErrors.length > 0}
              label="AOI (WKT)"
              variant="outlined"
              multiline
              rows={8}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: isAOIValid ? (areAOIsEquivalent(myAOI, aoi) ? 'white !important' : 'green') : 'red',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: isAOIValid ? (areAOIsEquivalent(myAOI, aoi) ? 'white !important' : 'green') : 'red',
                  },
                  '&:not(.Mui-focused):not(:hover) fieldset': {
                    borderColor: isAOIValid ? (areAOIsEquivalent(myAOI, aoi) ? 'white !important' : 'green') : 'red',
                  },
                },
                '& input': {
                  color: isAOIValid ? (areAOIsEquivalent(myAOI, aoi) ? 'white !important' : 'green') : 'red',
                },
                '& .MuiAutocomplete-popupIndicator': {
                  color: isAOIValid ? (areAOIsEquivalent(myAOI, aoi) ? 'white !important' : 'green') : 'red',
                },
                '& .MuiAutocomplete-clearIndicator': {
                  color: isAOIValid ? (areAOIsEquivalent(myAOI, aoi) ? 'white !important' : 'green') : 'red',
                },
              }}
              InputProps={{
                style: {
                  color: 'white', // Set the text color to white
                },
              }}
              InputLabelProps={
                                {
                                  style: {
                                    color: isAOIValid ? (areAOIsEquivalent(myAOI, aoi) ? 'white' : 'green') : 'red',
                                  },
                                }
                            }
              value={aoi}
              onChange={(event) => setAoi(event.target.value)}
            />
          </Tooltip>

          { /* Confirm AOI Button */}
          <Button
            variant="contained"
            disabled={!isAOIValid}
            sx={{
              width: '100%',
              marginTop: '16px',
              backgroundColor: 'rgb(16, 52, 88)', // Enabled background color
              color: 'white', // Enabled text color
              '&:hover': {
                backgroundColor: 'rgb(28, 71, 117)', // Enabled background color on hover
              },
              '&:disabled': {
                backgroundColor: '#7D8387', // Disabled background color
                color: 'rgba(0, 0, 0, 0.5)', // Disabled text color
              },
            }}
            onClick={() => confirmAOI()}
          >
            Confirm
          </Button>

          { /* Clear AOI Button */}
          <Button
            variant="contained"
            sx={{
              width: '100%',
              marginTop: '16px',
              backgroundColor: 'rgb(16, 52, 88)', // Enabled background color
              color: 'white', // Enabled text color
              '&:hover': {
                backgroundColor: 'rgb(28, 71, 117)', // Enabled background color on hover
              },
            }}
            onClick={() => handleClearMap()}
          >
            Clear
          </Button>

          { /* Snackbar shown when the AOI is confirmed for 2 seconds */}
          {showSnackbar
                        && (
                        <Snackbar
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          open={showSnackbar}
                          key="bottomright"
                        >
                          <Alert severity="success" sx={{ width: '100%' }}>
                            AOI was confirmed!
                          </Alert>
                        </Snackbar>
                        )}

        </Box>
      </Box>
    </Box>
  );
}

export default SettingsAOIMap;
