// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#toolbar {
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(28,71,117,1) 0.8%, rgba(2,2,2,1) 39.6%, rgba(2,2,2,1) 59.7%, rgba(28,71,117,1) 99.3% );
    color: white;
    border: white;
    background-size: 100%;
    min-height: 100vh;
    height: auto;
}

.sidebar-icons {
    color: white;
}

.logo-container {
    display: flex;
    justify-content: center;
    padding: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/styles/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,kKAAkK;IAClK,YAAY;IACZ,aAAa;IACb,qBAAqB;IACrB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,aAAa;EACf","sourcesContent":["#toolbar {\n    background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(28,71,117,1) 0.8%, rgba(2,2,2,1) 39.6%, rgba(2,2,2,1) 59.7%, rgba(28,71,117,1) 99.3% );\n    color: white;\n    border: white;\n    background-size: 100%;\n    min-height: 100vh;\n    height: auto;\n}\n\n.sidebar-icons {\n    color: white;\n}\n\n.logo-container {\n    display: flex;\n    justify-content: center;\n    padding: 20px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
