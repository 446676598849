import { useEffect, useState } from 'react';
import { getProjectStacks } from '../util/launchpadRequests';

/**
 * Retrieves all available stacks for a given project.
 * @param {String} chosenProject - The name of the project to retrieve stacks for.
 * @returns {Array} An array of stacks for the given project.
 */
export default function useProjectStacks(chosenProject) {
  const [projectStacks, setProjectStacks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      // Check if chosenProject is empty
      if (chosenProject !== '') {
        const stacks = await getProjectStacks(chosenProject);
        setProjectStacks(stacks);
      } else {
        setProjectStacks([]);
      }
    };

    fetchData();
  }, [chosenProject]);

  return { projectStacks };
}
