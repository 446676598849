import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Login from './screens/Login';
import Profile from './screens/Profile';
import Setup from './screens/Setup';
import Settings from './screens/Settings';
import NewLaunchpad from './screens/NewLaunchpad';
import ErrorScreen from './screens/ErrorScreen';

// Date picker

export default function App() {
  function ProtectedRoute({ children }) {
    const isAuth = JSON.parse(sessionStorage.getItem('login_status'));
    if (!isAuth) {
      return <Navigate to="/login" replace />;
    }

    return children;
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <BrowserRouter>
        <Routes>
          {/* When adding new routes make sure to use  'element' and not component. */}
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/setup"
            element={(
              <ProtectedRoute>
                <Setup />
              </ProtectedRoute>
          )}
          />
          <Route
            exact
            path="/settings"
            element={(
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
          )}
          />
          <Route
            exact
            path="newlaunchpad"
            element={(
              <ProtectedRoute>
                <NewLaunchpad />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path="profile"
            element={(
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
          )}
          />
          <Route path="/*" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </LocalizationProvider>
  );
}
