import * as React from 'react';
import {
  Box,
} from '@mui/material';
import ProcessBox from './ProcessBox';
import DetailedError from '../util/errors';

import projectStatesMap from '../util/projectStatesMap';
import {
  runProcess, killProcess, getNetworkProgress, getPrepProgress, getDensificationProgress,
} from '../util/launchpadRequests';

const typeMap = {
  'Stack preparation': 'prep',
  Network: 'network',
  Densification: 'densification',
};

export default function StackProgressBox({
  project, stack, aois, progress, setter, onError,
}) {
  const [options, setOptions] = React.useState(projectStatesMap(progress[stack]));

  const prepIntervalRef = React.useRef(null);
  const networkIntervalRef = React.useRef(null);
  const densificationIntervalRef = React.useRef(null);

  /**
   * Handles the click on the "Run" button for a process.
   * @param {string} type - the process type (prep/network/densification).
   */
  const handleRunProcess = async (type) => {
    const processType = typeMap[type];

    try {
      setter((prevData) => ({
        ...prevData,
        [stack]: {
          ...prevData[stack],
          [processType]: {
            status: 'PENDING',
            logs: ['Process starting.'],
            timestamps: ['', ''],
          },
        },
      }));

      const response = await runProcess(project, stack, processType);
      console.log('RUN RESPONSE: ', response.data);

      if (response.data.error && response.data.error === 'ALREADY_RUNNING') {
        setter((prevData) => ({
          ...prevData,
          [stack]: {
            ...prevData[stack],
            [processType]: {
              status: 'ERROR',
              logs: ['The process is already running. Please reload the project to get progress updates.'],
              timestamps: ['', ''],
            },
          },
        }));
      } else if (response.data === 'ERROR') {
        setter((prevData) => ({
          ...prevData,
          [stack]: {
            ...prevData[stack],
            [processType]: {
              status: 'ERROR',
              logs: ['Error starting process.'],
              timestamps: ['', ''],
            },
          },
        }));
      } else {
        setter((prevData) => ({
          ...prevData,
          [stack]: {
            ...prevData[stack],
            [processType]: {
              ...prevData[processType],
              status: 'PENDING',
              logs: ['Process starting.'],
              timestamps: ['', ''],
            },
          },
        }));
      }
    } catch (error) {
      setter((prevData) => ({
        ...prevData,
        [stack]: {
          ...prevData[stack],
          [processType]: {
            status: 'ERROR',
            logs: ['Error starting process.'],
            timestamps: ['', ''],
          },
        },
      }));
      console.error('Error running process:', error);
    }
  };

  /**
   * Handles the click on the "Kill" button for a process.
   * @param {string} type - the process type (prep/network/densification).
   */
  const handleKillProcess = async (type) => {
    const processType = typeMap[type];

    try {
      setter((prevData) => ({
        ...prevData,
        [stack]: {
          ...prevData[stack],
          [processType]: {
            status: 'PENDING',
            logs: ['Process is being killed.'],
            timestamps: ['', ''],
          },
        },
      }));

      const response = await killProcess(project, stack, processType);

      if (response.data === 'ERROR') {
        setter((prevData) => ({
          ...prevData,
          [stack]: {
            ...prevData[stack],
            [processType]: {
              status: 'ERROR',
              logs: ['Error killing process.'],
              timestamps: ['', ''],
            },
          },
        }));
      } else {
        setter((prevData) => ({
          ...prevData,
          [stack]: {
            ...prevData[stack],
            [processType]: {
              ...prevData[processType],
              status: 'PENDING',
              logs: ['Process is being killed.'],
              timestamps: ['', ''],
            },
          },
        }));
      }
    } catch (error) {
      setter((prevData) => ({
        ...prevData,
        [stack]: {
          ...prevData[stack],
          [processType]: {
            ...prevData[processType],
            status: 'ERROR',
            logs: ['Error killing process.'],
            timestamps: ['', ''],
          },
        },
      }));
      console.error('Error killing process:', error);
    }
  };

  React.useEffect(() => {
    setOptions(projectStatesMap(progress[stack]));
  }, [progress[stack]]);

  React.useEffect(() => {
    const startInterval = async (processType, setIntervalRef) => {
      try {
        const intervalId = setInterval(async () => {
          let processProgress;

          switch (processType) {
            case 'prep':

              try {
                processProgress = await getPrepProgress(project, stack);
              } catch (err) {
                onError(err.message, err.detailedMessage);
                return;
              }

              break;

            case 'network':
              try {
                processProgress = await getNetworkProgress(project, stack);
              } catch (err) {
                onError(err.message, err.detailedMessage);
                return;
              }

              break;

            case 'densification':
              try {
                processProgress = await getDensificationProgress(project, stack);
              } catch (err) {
                onError(err.message, err.detailedMessage);
                return;
              }

              break;

            default:
              console.error('Invalid process type:', processType);
              return;
          }

          setter((prevData) => ({
            ...prevData,
            [stack]: {
              ...prevData[stack],
              [processType]: processProgress,
            },
          }));
        }, 60 * 1000); // 60 * 1000 milliseconds = 1 minute

        // Save the interval ID to the ref
        setIntervalRef.current = intervalId;
      } catch (error) {
        console.error(`Error fetching ${processType} progress:`, error);
      }
    };

    if (['PENDING', 'RECEIVED', 'RETRY', 'STARTED', 'RUNNING', 'UPDATE_RUNNING'].includes(progress[stack].prep.status)) {
      if (!prepIntervalRef.current) {
        startInterval('prep', prepIntervalRef);
      }
    } else {
      clearInterval(prepIntervalRef.current);
      prepIntervalRef.current = null;
    }

    if (['PENDING', 'RECEIVED', 'RETRY', 'STARTED'].includes(progress[stack].network.status)) {
      if (!networkIntervalRef.current) {
        startInterval('network', networkIntervalRef);
      }
    } else {
      clearInterval(networkIntervalRef.current);
      networkIntervalRef.current = null;
    }

    if (['PENDING', 'RECEIVED', 'RETRY', 'STARTED'].includes(progress[stack].densification.status)) {
      if (!densificationIntervalRef.current) {
        startInterval('densification', densificationIntervalRef);
      }
    } else {
      clearInterval(densificationIntervalRef.current);
      densificationIntervalRef.current = null;
    }

    // Cleanup intervals when the component unmounts
    return () => {
      clearInterval(prepIntervalRef.current);
      prepIntervalRef.current = null;
      clearInterval(networkIntervalRef.current);
      networkIntervalRef.current = null;
      clearInterval(densificationIntervalRef.current);
      densificationIntervalRef.current = null;
    };
  }, [progress[stack]]);

  return (
    <Box>
      <h1>{stack}</h1>
      {progress
          && (
          <Box sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 1,
          }}
          >
            <ProcessBox
              project={project}
              stack={stack}
              aoi={null}
              process={{
                type: 'Stack preparation', ...progress[stack].prep,
              }}
              states={options.prep}
              onRunProcess={(type) => handleRunProcess(type)}
              onKillProcess={(type) => handleKillProcess(type)}
            />
            <ProcessBox
              project={project}
              stack={stack}
              aoi={aois.network}
              process={{
                type: 'Network', ...progress[stack].network,
              }}
              states={options.network}
              onRunProcess={(type) => handleRunProcess(type)}
              onKillProcess={(type) => handleKillProcess(type)}
            />
            <ProcessBox
              project={project}
              stack={stack}
              aoi={aois.densification}
              process={{
                type: 'Densification', ...progress[stack].densification,
              }}
              states={options.densification}
              onRunProcess={(type) => handleRunProcess(type)}
              onKillProcess={(type) => handleKillProcess(type)}
            />
          </Box>
          )}
    </Box>

  );
}
