import * as React from 'react';
import axios from 'axios';
import {
  Box, Button, Divider, TextField, Typography,
} from '@mui/material';
import { users } from '../util/API';
import Sidebar from '../components/Sidebar';

import InactiveTimer from '../components/inactiveTimer';
import logout from '../util/logout';
import '../styles/Profile.css';

const drawerWidth = 240;

export default function Profile() {
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [firstField, setFirstField] = React.useState(true);
  const [readyToUpdate, setReadyToUpdate] = React.useState(true);
  const [output, setOutput] = React.useState('');

  const token = JSON.parse(sessionStorage.getItem('token'));
  const PASSWORD_UPDATE_URL = `${users}<user_id>/set_password/`;

  /**
   * Creates a new project with the given parameters.
   */
  const updatePassword = async () => {
    try {
      const user = JSON.parse(sessionStorage.getItem('user'));
      const userId = user.id;

      await axios.put(
        PASSWORD_UPDATE_URL.replace('<user_id>', userId),
        {
          old_password: oldPassword,
          new_password: newPassword,
          user: userId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setOutput('Password changed!');
    } catch (error) {
      setOutput('Error: Password not changed.');
      console.log(error);
    }
  };

  /**
   * Checks for inactivity every 60 seconds.
   */
  React.useEffect(() => {
    const timer = new InactiveTimer({
      timeout: 3600,
      // If timer expires when the user is in the app
      onTimeout: () => {
        logout();
      },
    });

    return () => {
      timer.cleanup();
    };
  }, []);

  return (
    <div id="profile-background">
      <Box sx={{ display: 'flex' }}>

        {/* Left sidebar */}
        <Sidebar />

        {/* Main content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1, p: 3, m: 1, width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >

          <h1>Change your password</h1>
          <Box sx={{ width: 500 }}>
            <Box
              sx={{
                display: 'grid',
                gridAutoColumns: '1fr',
                gridAutoFlow: 'row',
                gap: 1,
              }}
            >

              <TextField
                id="outlined-basic"
                label="Current password"
                type="password"
                variant="outlined"
                margin="dense"
                sx={{
                  width: 350,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white', // Set the outline color to white
                    },
                    '&:hover fieldset': {
                      borderColor: 'white', // Set the outline color to white on hover
                    },
                  },
                }}
                onChange={(event) => {
                  setOldPassword(event.target.value);
                  setFirstField(false);
                }}
                inputProps={{
                  style: {
                    color: 'white', // Set the text color to white
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'white', // Set the label color to white
                  },
                }}
              />
              <TextField
                id="outlined-basic"
                label="New password"
                type="password"
                variant="outlined"
                margin="dense"
                sx={{
                  width: 350,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white', // Set the outline color to white
                    },
                    '&:hover fieldset': {
                      borderColor: 'white', // Set the outline color to white on hover
                    },
                  },
                }}
                onChange={(event) => {
                  setNewPassword(event.target.value); setReadyToUpdate(false);
                }}
                inputProps={{
                  style: {
                    color: 'white', // Set the text color to white
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'white', // Set the label color to white
                  },
                }}
              />
            </Box>
            {/* All need to be true for the button to be enabled */}
            <Button
              variant="contained"
              sx={{
                marginTop: 2,
                marginBottom: 2,
                gridRow: 1,
                width: 350,
                backgroundColor: 'rgb(16, 52, 88)', // Enabled background color
                color: 'white', // Enabled text color
                '&:hover': {
                  backgroundColor: 'rgb(28, 71, 117)', // Enabled background color on hover
                },
                '&:disabled': {
                  backgroundColor: '#7D8387', // Disabled background color
                  color: 'rgba(0, 0, 0, 0.5)', // Disabled text color
                },
              }}
              disabled={readyToUpdate || firstField}
              onClickCapture={() => {
                updatePassword();
              }}
            >
              Update
            </Button>
          </Box>

          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

          <h2 style={{ marginTop: 15, marginBottom: 15 }}>Output</h2>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
            <Typography>
              {Object.values(output)}
            </Typography>
          </Box>

        </Box>

      </Box>
    </div>

  );
}
