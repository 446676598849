import React from 'react';
import { Box, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function DateRangePicker({ dates, setFilteredDates }) {
  const [startingDateFilter, setStartingDateFilter] = React.useState(null);
  const [endingDateFilter, setEndingDateFilter] = React.useState(null);

  /**
     * Transform date from the DatePicker format to 'YYYY-MM-DD' format
     * @param {String} dateString - A date string in the DatePicker format
     * @returns The same date in 'YYYY-MM-DD' format
     */
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
  };

  /**
     * Handler for changing the starting date
     * @param date - the date chosen by the user
     */
  const handleStartDateChange = (date) => {
    console.log(formatDate(date.$d));
    setStartingDateFilter(date);
  };

  /**
     * Handler for changing the ending date
     * @param date - the date chosen by the user
     */
  const handleEndDateChange = (date) => {
    setEndingDateFilter(date);
  };

  /**
     * Resets date filters back to null and displays all available dates
     */
  const resetFilters = () => {
    setStartingDateFilter(null);
    setEndingDateFilter(null);
    setFilteredDates(dates);
  };

  /**
     * Filters the dates based on the starting and ending date
     * @param {String} start - The starting date of the range
     * @param {String} end - The ending date of the range
     */
  const filterDates = (start, end) => {
    let filtered;

    if (start === null && end === null) {
      alert('Please select a starting and ending date!');
    } else {
      // Filter all dates until end date
      if (start === null) {
        filtered = dates.filter((date) => date.date <= formatDate(end));

        if (filtered !== undefined && filtered.length > 0) {
          setFilteredDates(filtered);
        } else {
          alert('No dates found in the chosen range!');
        }
      } else if (end === null) { // Filter all dates after start date
        filtered = dates.filter((date) => date.date >= formatDate(start));

        if (filtered !== undefined && filtered.length > 0) {
          setFilteredDates(filtered);
        } else {
          alert('No dates found in the chosen range!');
        }
      } else if (formatDate(start) < formatDate(end)) { // Filter all dates between start and end
        filtered = dates.filter(
          (date) => date.date >= formatDate(start) && date.date <= formatDate(end),
        );

        if (filtered !== undefined && filtered.length > 0) {
          setFilteredDates(filtered);
        } else {
          alert('No dates found in the chosen range!');
        }
      } else {
        alert('The starting date must be before the ending date!');
        setEndingDateFilter(null);
        setStartingDateFilter(null);
      }
    }
  };

  return (
    <Box style={{
      display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%',
    }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {' '}
        {/* Add marginRight */}
        <h2> Starting date </h2>
        <DatePicker value={startingDateFilter} onChange={handleStartDateChange} views={['year', 'month', 'day']} />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <h2> Ending date </h2>
        <DatePicker value={endingDateFilter} onChange={handleEndDateChange} views={['year', 'month', 'day']} />
      </Box>

      <Button
        variant="contained"
        sx={{
          marginTop: 9.6,
          marginBottom: 2,
          width: 100,
          height: 40,
          backgroundColor: 'rgb(16, 52, 88)',
          color: 'white',
          '&:hover': {
            backgroundColor: 'rgb(28, 71, 117)',
          },
          '&:disabled': {
            backgroundColor: '#7D8387',
            color: 'rgba(0, 0, 0, 0.5)',
          },
        }}
        onClickCapture={() => {
          filterDates(startingDateFilter, endingDateFilter);
        }}
      >
        Filter
      </Button>
      <Button
        variant="contained"
        disabled={startingDateFilter === null && endingDateFilter === null}
        sx={{
          marginTop: 9.6,
          marginBottom: 2,
          width: 100,
          height: 40,
          backgroundColor: 'rgb(16, 52, 88)',
          color: 'white',
          '&:hover': {
            backgroundColor: 'rgb(28, 71, 117)',
          },
          '&:disabled': {
            backgroundColor: '#7D8387',
            color: 'rgba(0, 0, 0, 0.5)',
          },
        }}
        onClickCapture={() => {
          resetFilters();
        }}
      >
        Reset
      </Button>
    </Box>
  );
}

export default DateRangePicker;
