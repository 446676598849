import React from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Button, Box, IconButton, Divider, ButtonGroup,
} from '@mui/material';

// icons
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function StackSelector({ intersectingStacks, setIntersectingStacks }) {
  const satelliteMap = {
    csk: 'CSK',
    tsx: 'TSX',
    env: 'ENVISAT',
    ers: 'ERS2',
    s1: 'SENTINEL-1',
    rsat2: 'RS2',
  };

  /**
     * Marks the chosen stack as selected or not selected.
     * @param {String} stackName - the name of the stack that was clicked.
     */
  const handleStackSelection = (stackName) => {
    const updatedStacks = intersectingStacks.map(([name, aoi, satellite, selected, inAzure]) => {
      if (stackName === name) {
        selected = !selected;
      }
      return [name, aoi, satellite, selected, inAzure];
    });

    setIntersectingStacks(updatedStacks);
  };

  /**
     * Groups the stacks by satellite.
     * @param {Array} intersectingStacks - the stacks that intersect the AOI.
     * @returns {Object} - the stacks grouped by satellite.
     */
  const stacksBySatellite = intersectingStacks.reduce((acc, [name, aoi, satellite, selected, inAzure]) => {
    if (!acc[satellite]) {
      acc[satellite] = [];
    }
    acc[satellite].push({ name, selected, inAzure });
    return acc;
  }, {});

  return (
    <>
      {Object.entries(stacksBySatellite).map(([satellite, stacks]) => (
        <Accordion
          key={satellite}
          sx={{
            marginTop: 1, marginBottom: 1, borderRadius: 2, bgcolor: '#103458',
          }}
        >
          <AccordionSummary sx={{ color: 'white' }} expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>{satelliteMap[satellite]}</AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {stacks.map(({ name, selected, inAzure }) => {
                const buttonStyles = selected
                  ? {
                    color: 'white', backgroundColor: '#1A5C25', borderColor: '#1A5C25', fontWeight: 'bold',
                  }
                  : { color: 'white', borderColor: 'white', fontWeight: 'normal' };

                const handleCopyClick = () => {
                  navigator.clipboard.writeText(name)
                    .then(() => {
                      console.log('Text copied to clipboard');
                    })
                    .catch((error) => {
                      console.error('Error copying text:', error);
                    });
                };

                return (
                  <ButtonGroup
                    key={name}
                    style={{
                      display: 'flex',
                      margin: '10px',
                      width: 'fit-content',
                      border: '1px solid white',
                      ...buttonStyles,
                    }}
                  >
                    <Button
                      variant="text"
                      onClick={() => handleStackSelection(name)}
                      sx={{ flex: 1, color: 'white' }}
                      disableRipple
                    >
                      {inAzure ? (
                        <>
                          <img src="azr.png" alt="Azure" style={{ marginRight: '5px', width: 'fit-content', height: 15 }} />
                          {name}
                        </>
                      ) : name}
                    </Button>
                    <Divider orientation="vertical" flexItem style={{ width: '2px' }} />
                    <IconButton onClick={handleCopyClick} aria-label="copy">
                      <ContentCopyIcon sx={{ color: 'white' }} />
                    </IconButton>
                  </ButtonGroup>
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}

export default StackSelector;
