// Helper functions that deal with geometry, AOIs.
import { intersect } from '@turf/turf';
import { booleanValid } from "@turf/boolean-valid";
import { polygon } from "@turf/helpers"

const wkt = require('wkt');

/**
 * Extracts the points from a WKT string.
 * @param {String} wktString - the given string, in WKT format.
 * @returns {Array} - the points in the string, in [lat, lng] format.
 */
export function extractPoints(wktString) {
  const toReplace = new RegExp('POLYGON\\(\\(', 'i');
  wktString = wktString.replace(toReplace, '');

  const coordinatesString = wktString.replace(/\)\)/, '');

  if (coordinatesString.trim() === '') {
    return [];
  }

  const coordinatePairs = coordinatesString.split(',');

  // Extract latitude and longitude values
  const points = coordinatePairs.map((pair) => {
    const [lng, lat] = pair.trim().split(' ').map(parseFloat);
    return { lng, lat };
  });

  return points;
}

/**
 * Extracts the WKT string from a list of points.
 * @param {Array} points - a list of points in [lat, lng] format.
 * @returns {String} - the WKT string.
 */
export function extractWKT(points) {
  return `POLYGON((${points.map((point) => `${point.lng} ${point.lat}`).join(', ')}))`;
}

/**
    * Calculates the center point from a list of points.
    * @param {Array} points - a list of points in [lat, lng] format.
    * @returns {Array} - The center point, if the list is not empty. [30, 0] otherwise.
    */
export function aoiCenter(points) {
  if (points.length > 0) {
    const x = points.map((xy) => xy[0]);
    const y = points.map((xy) => xy[1]);
    const cx = (Math.min(...x) + Math.max(...x)) / 2;
    const cy = (Math.min(...y) + Math.max(...y)) / 2;

    return [cx, cy];
  }

  return [30, 0];
}

/**
 * Returns a list of points that are 5% of the distance from the centroid to the original points.
 * @param {Array} points - a list of points in [lat, lng] format.
 * @returns {Array} - The enclosed polygon, if the list has three or more points.
 *                    The original list otherwise.
 */
export function enclosedArea(points) {
  if (points.length >= 3) {
    const centroid = aoiCenter(points);

    const enclosedPolygon = points.map(([lat, lng]) => {
      const latDiff = lat - centroid[0];
      const lngDiff = lng - centroid[1];
      const smallerLat = centroid[0] + latDiff * 0.05;
      const smallerLng = centroid[1] + lngDiff * 0.05;

      return [smallerLat, smallerLng];
    });

    return enclosedPolygon;
  }

  return points;
}

/**
    * Calculates the bounding box of a list of points.
    * @param {Array} newPoints - a list of points in [lat, lng] format.
    * @returns {Array} - The bounding box, if the list is not empty.
    *                    [[-100, -200], [100, 200]] otherwise.
    */
export function calculateMaxBounds(newPoints) {
  if (newPoints.length > 0) {
    let minLat = newPoints[0][0];
    let maxLat = newPoints[0][0];
    let minLng = newPoints[0][1];
    let maxLng = newPoints[0][1];

    newPoints.forEach((point) => {
      const [lat, lng] = point;
      minLat = Math.min(minLat, lat);
      maxLat = Math.max(maxLat, lat);
      minLng = Math.min(minLng, lng);
      maxLng = Math.max(maxLng, lng);
    });

    const bounds = [[minLat, minLng], [maxLat, maxLng]];
    return bounds;
  }

  return [[-100, -200], [100, 200]];
}

/**
    * Check if two polygons overlap.
    * @param {Array} polygon1 - The coordinates of points in the first polygon in [lat, lng] format.
    * @param {Array} polygon2 - The coordinates of points in the second polygon
    *                           in [lat, lng] format.
    * @returns {boolean} - True if the polygons overlap, false otherwise.
    */
export function checkPolygonOverlap(polygon1, polygon2) {
  if (polygon1[0] !== polygon1[polygon1.length - 1]) {
    polygon1.push(polygon1[0]);
  }

  if (polygon2[0] !== polygon2[polygon2.length - 1]) {
    polygon2.push(polygon2[0]);
  }

  const p1 = polygon([polygon1]);
  const p2 = polygon([polygon2]);

  const intersection = intersect(p1, p2);
  if (intersection) {
    return true;
  }
  return false;
}

/**
 * Removes duplicate points from a list of points.
 * @param {Array} points - a list of points in [lat, lng] format.
 * @returns {Array} The list of points with duplicates removed.
 */
export function removeDuplicatePoints(points) {
  const uniquePoints = [];
  points.forEach((point) => {
    if (!uniquePoints.some(
      (uniquePoint) => uniquePoint.lat === point.lat && uniquePoint.lng === point.lng
    )) {
      uniquePoints.push(point);
    }
  });

  return uniquePoints;
}

/**
 * Closes a polygon by adding the first point to the end of the list of points.
 * @param {String} polygonString - the given string, in WKT format.
 * @returns {String} The closed polygon, in WKT format.
 */
export function closePolygon(polygonString) {
  const points = removeDuplicatePoints(extractPoints(polygonString));

  if (points.length > 0) {
    const firstPoint = points[0];
    const lastPoint = points[points.length - 1];

    if (firstPoint.lat !== lastPoint.lat || firstPoint.lng !== lastPoint.lng) {
      points.push(firstPoint);
    }

    return extractWKT(points);
  }

  return 'POLYGON(())';
}

/**
 * Checks if a given polygon in WKT format is valid or not.
 * @param {String} polygonString - the given AOI, in WKT format.
 * @returns {Boolean} Whether the polygon is valid or not.
 */
export function isPolygonValid(polygonString) {
  try {
    const geometry = wkt.parse(polygonString);

    if (geometry.type !== 'Polygon') {
      return false;
    }

    const turfPolygon = polygon(geometry.coordinates);
    if (booleanValid(turfPolygon)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}
