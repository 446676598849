import * as React from 'react';
import {
  Box, Typography, Tooltip, TextField,
} from '@mui/material';

// Accordion imports
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import schema from '../util/schema.json';

import { handleNetworkSettingsChange } from '../util/settingsHandlers';

// Utils
import { generateTooltips, generateSettingsLabelStyle, generateSettingsTextFieldStyle } from '../util/settingsUtils';
import SettingsAOIMap from './settingsAOIMap';

function NetworkSettingsTab({
  networkSettings, setNetworkSettings, originalAOI, chosenStackAOI,
}) {
  const sections = ['general', 'aps_estimation', 'defo_estimation', 'quick_densification'];

  console.log('Network settings: ', networkSettings);

  return (

    <Accordion sx={{ marginBottom: 2, borderRadius: 2, bgcolor: '#548ac9' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Network Settings</Typography>
      </AccordionSummary>
      <AccordionDetails>

        {sections.map((section) => {
          const currentSection = networkSettings[section];
          console.log('Current section: ', currentSection);
          const schemaCurrentSection = schema.properties.network.properties[section].properties;

          return (
            <Accordion sx={{ marginBottom: 2, borderRadius: 2, bgcolor: '#6f9dd1' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  {
                                        section === 'general' ? 'General Settings'
                                          : section === 'aps_estimation' ? 'APS Estimation Settings'
                                            : section === 'defo_estimation' ? 'Defo Estimation Settings'
                                              : 'Quick Densification Settings'
                                    }
                </Typography>
              </AccordionSummary>
              <AccordionDetails>

                <Box sx={{ display: 'grid', gridTemplateColumns: currentSection && section !== 'general' ? 'repeat(2, 1fr)' : '1fr', gap: 2 }}>
                  {currentSection && Object.entries(currentSection).map(([key, value]) => {
                    // If section is general, compare to the previous saved AOI value since AOI default is NULL.
                    // Otherwise, use the default value from the schema.
                    const compareTo = section === 'general' ? originalAOI : schemaCurrentSection[key].default;

                    if (key === 'aoi') {
                      if (schemaCurrentSection.aoi !== undefined && value !== undefined) {
                        return (
                          <SettingsAOIMap
                            myAOI={value}
                            stackAOI={chosenStackAOI}
                            settings={networkSettings}
                            setter={setNetworkSettings}
                            fields={['general']}
                            schema={schemaCurrentSection.aoi}
                            parentAccordionColor="#6f9dd1"
                          />
                        );
                      }
                    } else {
                      return (
                        <Tooltip
                          title={(
                            <span style={{ whiteSpace: 'pre-line' }}>
                              {generateTooltips(schemaCurrentSection[key])}
                            </span>
                                                      )}
                          placement="top"
                          arrow
                        >
                          <TextField
                            label={key}
                            InputLabelProps={generateSettingsLabelStyle(currentSection[key], compareTo)}
                            defaultValue={currentSection[key]}
                            sx={generateSettingsTextFieldStyle(currentSection[key], compareTo)}
                            onBlur={(e) => {
                              handleNetworkSettingsChange(e, section, key, chosenStackAOI, networkSettings, setNetworkSettings);
                            }}
                          />
                        </Tooltip>
                      );
                    }
                  })}
                </Box>

              </AccordionDetails>
            </Accordion>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}

export default NetworkSettingsTab;
