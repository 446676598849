import { useMapEvent } from 'react-leaflet';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

const MarkerHandler = ({
  markers,
  setMarkers,
  setAoi,
  isPopupClicked,
  setPopupClicked,
  isMarkerRemoved,
  setIsMarkerRemoved,
}) => {
  useMapEvent('click', (e) => {
    if (isPopupClicked) {
      setPopupClicked(false);
    } else if (!isMarkerRemoved) { // Check if a marker was recently removed
      const { lat, lng } = e.latlng;
      const updatedMarkers = [...markers, { id: uuidv4(), lat, lng }];
      setMarkers(updatedMarkers);
    }
  });

  useEffect(() => {
    const updatedAoi = markers.map((marker) => `${marker.lng} ${marker.lat}`).join(', ');
    setAoi(`POLYGON((${updatedAoi}))`);
  }, [markers, setAoi]);

  useEffect(() => {
    setIsMarkerRemoved(false); // Reset the isMarkerRemoved state
  }, [markers, setIsMarkerRemoved]);
};

export default MarkerHandler;
