import axios from 'axios';
import { getProjectAoisUrl } from './API';

export default async function getProjectAOIs(projectName, stackName) {
  const token = JSON.parse(sessionStorage.getItem('token'));
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    params: {
      project: projectName,
      stack: stackName,
    },
  };

  try {
    const response = await axios.get(getProjectAoisUrl, options);
    return response.data;
  } catch (error) {
    console.error('Error retrieving project AOIs:', error);
    throw error;
  }
}
