import * as React from 'react';
import {
  Box, Typography, Tooltip, TextField,
} from '@mui/material';

// Accordion imports
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Schema
import schema from '../util/schema.json';

// Handler
import { handleDensificationSettingsChange } from '../util/settingsHandlers';

// Utils
import { generateTooltips, generateSettingsLabelStyle, generateSettingsTextFieldStyle } from '../util/settingsUtils';
import SettingsAOIMap from './settingsAOIMap';

function DensificationSettingsTab({
  projectSettings, densificationSettings, setDensificationSettings, chosenStackAOI,
}) {
  const sections = [
    { key: 'densification_ps', label: 'Densification PS' },
    { key: 'densification_intermittent_ps', label: 'Densification Intermittent PS' },
    { key: 'densification_ods', label: 'Densification ODS' },
  ];
  const subsections = ['general', 'defo_estimation'];

  return (
    <Accordion sx={{ marginBottom: 2, borderRadius: 2, bgcolor: '#548ac9' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Densification Settings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {sections.map((section) => (
          <Accordion key={section.key} sx={{ marginBottom: 2, borderRadius: 2, bgcolor: '#6f9dd1' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{section.label}</Typography>
            </AccordionSummary>
            {densificationSettings[section.key]

                            && (
                            <AccordionDetails>

                                {subsections.map((subsection) => (densificationSettings[section.key][subsection] ? (
                                  <Accordion key={subsection} sx={{ marginBottom: 2, borderRadius: 2, bgcolor: '#8aafda' }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                      <Typography>
                                        {subsection === 'general' ? 'General' : 'Defo Estimation'}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      {subsection === 'defo_estimation'
                                                    && densificationSettings[section.key][subsection][`${section.key.substring(section.key.indexOf('densification_') + 'densification_'.length)}_settings`] ? (
                                                      <Accordion sx={{ marginBottom: 2, borderRadius: 2, bgcolor: '#a5c2e2' }}>
                                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                          <Typography>
                                                            {`${section.key.substring(section.key.indexOf('densification_') + 'densification_'.length)}_settings`.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                                          </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
                                                            {densificationSettings[section.key][subsection][`${section.key.substring(section.key.indexOf('densification_') + 'densification_'.length)}_settings`]
                                                                    && Object.entries(densificationSettings[section.key][subsection][`${section.key.substring(section.key.indexOf('densification_') + 'densification_'.length)}_settings`]).map(([key, value]) => {
                                                                      const subsubsection = `${section.key.substring(section.key.indexOf('densification_') + 'densification_'.length)}_settings`;
                                                                      const schemaDefault = schema.properties[section.key].properties[subsection].properties[subsubsection].properties[key].default;

                                                                      return (
                                                                        <Tooltip
                                                                          title={(
                                                                            <span style={{ whiteSpace: 'pre-line' }}>
                                                                              {generateTooltips(schema.properties[section.key].properties[subsection].properties[subsubsection].properties[key])}
                                                                            </span>
                                                                                  )}
                                                                          placement="top"
                                                                          arrow
                                                                        >
                                                                          <TextField
                                                                            label={key}
                                                                            InputLabelProps={generateSettingsLabelStyle(densificationSettings[section.key][subsection][subsubsection][key], schemaDefault)}
                                                                            defaultValue={densificationSettings[section.key][subsection][subsubsection][key]}
                                                                            sx={generateSettingsTextFieldStyle(densificationSettings[section.key][subsection][subsubsection][key], schemaDefault)}
                                                                            onBlur={(e) => {
                                                                              handleDensificationSettingsChange(e, `${section.key}.${subsection}.${subsubsection}.${key}`, chosenStackAOI, projectSettings, densificationSettings, setDensificationSettings);
                                                                            }}
                                                                          />
                                                                        </Tooltip>
                                                                      );
                                                                    })}
                                                          </Box>
                                                        </AccordionDetails>
                                                      </Accordion>
                                        ) : null}

                                      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
                                        {Object.entries(densificationSettings[section.key][subsection]).map(
                                          ([key, value]) => {
                                            if (subsection === 'defo_estimation' && key === `${section.key.substring(section.key.indexOf('densification_') + 'densification_'.length)}_settings`) {
                                              return null;
                                            }

                                            const schemaDefault = schema.properties[section.key].properties[subsection].properties[key].default;

                                            if (key !== 'aoi') {
                                              return (

                                                <Tooltip
                                                  key={key}
                                                  title={(
                                                    <span style={{ whiteSpace: 'pre-line' }}>
                                                      {generateTooltips(schema.properties[section.key].properties[subsection].properties[key])}
                                                    </span>
                                                                          )}
                                                  placement="top"
                                                  arrow
                                                >
                                                  <TextField
                                                    label={key}
                                                    InputLabelProps={generateSettingsLabelStyle(
                                                      densificationSettings[section.key][subsection][key],
                                                      schemaDefault,
                                                    )}
                                                    defaultValue={densificationSettings[section.key][subsection][key]}
                                                    sx={generateSettingsTextFieldStyle(
                                                      densificationSettings[section.key][subsection][key],
                                                      schemaDefault,
                                                    )}
                                                    onBlur={(e) => {
                                                      handleDensificationSettingsChange(
                                                        e,
                                                        `${section.key}.${subsection}.${key}`,
                                                        chosenStackAOI,
                                                        projectSettings,
                                                        densificationSettings,
                                                        setDensificationSettings,
                                                      );
                                                    }}
                                                  />
                                                </Tooltip>
                                              );
                                            }
                                          },
                                        )}
                                      </Box>

                                      {densificationSettings[section.key][subsection].aoi !== undefined
                                                    && schema.properties[section.key].properties[subsection].properties.aoi !== undefined

                                                    && (
                                                    <Box sx={{ marginTop: 3 }}>

                                                      <SettingsAOIMap
                                                        myAOI={densificationSettings[section.key][subsection].aoi}
                                                        stackAOI={chosenStackAOI}
                                                        settings={densificationSettings}
                                                        setter={setDensificationSettings}
                                                        fields={[section.key, subsection]}
                                                        schema={schema.properties[section.key].properties[subsection].properties.aoi}
                                                        parentAccordionColor="#8aafda"
                                                      />
                                                    </Box>
                                                    )}

                                    </AccordionDetails>
                                  </Accordion>
                                ) : null))}
                            </AccordionDetails>
                            )}
          </Accordion>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default DensificationSettingsTab;
